import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

export default function Oferta(props) {
  return (
    <Fragment>
      <div className="section-oferta">
        <div className="container-std">
          <h1 id="testelement" className="section-title">
            {props.lang === "RO" ? props.oferta.titlu : props.oferta.titlu_en}
          </h1>
          <div className="flex-oferta">
            <div className="container-oferta">
              <div className="text-oferta">
                <p className="paragraph-standard">
                  {props.lang === "RO"
                    ? props.oferta.text[0]
                    : props.oferta.text_en[0]}
                </p>
              </div>
              <div className="oferta-buttons">
                <Grid container spacing={2}>
                  <Grid item xs={6} md={12}>
                    <Link
                      className="button-oferta w-button"
                      style={{ width: "100%" }}
                      to={
                        props.lang === "RO"
                          ? `/oferte/${props.oferta.offerId}`
                          : `/en/offers/${props.oferta.offerId}`
                      }
                    >
                      {props.lang === "RO" ? "Află mai mult" : "Find out more"}
                    </Link>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Link
                      className="button-oferta w-button"
                      style={{ width: "100%" }}
                      to={
                        props.lang === "RO"
                          ? `/oferte/rezervari/${props.oferta.offerId}`
                          : `/en/offers/reservations/${props.oferta.offerId}`
                      }
                    >
                      {props.lang === "RO" ? "rezervă acum" : "book now"}
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
