import React from "react";

export default function Contact(props) {
  return (
    <div id="contact" className="section-standard">
      <div className="container-std">
        <h1 className="section-title">Contact</h1>
        <p className="paragraph-standard">
          {props.lang === "RO" ? props.contact.text : props.contact.text_en}
        </p>
        <div className="contact">
          <div className="contact-element">
            <p className="paragraph-standard">
              <strong>{props.lang === "RO" ? "Adresa: " : "Address: "}</strong>
              {props.contact.adresa}
            </p>
            <a
              href={props.contact.GPS}
              target="_blank"
              rel="noopener noreferrer"
              className="button-navigare w-button"
            >
              {props.lang === "RO" ? "Navigare gps" : "GPS Navigation"}
            </a>
            {props.contact.adresa2 && (
              <div style={{ marginTop: 20 }}>
                <p className="paragraph-standard">
                  <strong>
                    {props.lang === "RO" ? "Adresa: " : "Address: "}
                  </strong>
                  {props.contact.adresa2}
                </p>
                <a
                  href={props.contact.GPS2}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button-navigare w-button"
                >
                  {props.lang === "RO" ? "Navigare gps" : "GPS Navigation"}
                </a>
              </div>
            )}
          </div>
          <div className="contact-element">
            {props.contact.email.map((item) => (
              <p
                key={item}
                className="paragraph-standard"
                style={{
                  overflow: "hide",
                  width: "100%",
                  display: "block",
                  wordWrap: "break-word",
                }}
              >
                <strong>Email:</strong> <a href={`mailto:${item}`}>{item}</a>
              </p>
            ))}
            {props.contact.phone.map((item) => (
              <p key={item} className="paragraph-standard">
                <strong>{props.lang === "RO" ? "Telefon: " : "Phone: "}</strong>
                <a href={`tel:${item}`}>{item}</a>
              </p>
            ))}
            {props.contact.fax.map((item) => (
              <p key={item + "_fax"} className="paragraph-standard">
                <strong>Fax: </strong>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
