import React from "react";
import { useState, useEffect } from "react";

import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import OfertaModule from "./components/OfertaModule";
import Despre from "./components/Despre";
import SliderMain from "./components/SliderMain";
import CamereList from "./components/CamereList";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Separator from "./components/Separator";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import "./css/normalize.css";
import "./css/webflow.css";
import "./css/addhotel.webflow.css";
import "./css/my.css";
import "./css/simple_navbar.css";

import data from "./data/data.json";
import { Divider, Typography } from "@material-ui/core";
// import dataEN from "./data/en.json";

function App(props) {
  const [lang, setLang] = useState(props.lang ? props.lang : "RO");
  useEffect(() => {
    if (window.location.pathname.includes("/en/")) {
      setLang("EN");
    } else {
      setLang("RO");
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App the-spacer">
      <Navbar lang={lang} data={data} logo={data.logo} fb={data.fblink} />
      <div id="home">
        <Hero data={data} lang={lang} />
      </div>

      {data.oferte.map((item) => (
        <OfertaModule lang={lang} oferta={item} />
      ))}

      <Separator sepId="despre" />

      <Despre lang={lang} data={data} />
      <SliderMain lang={lang} images={data.sliderMain} />

      <Separator sepId="camere" />

      <div className="section-standard">
        <div className="container-std">
          <h1 className="section-title">
            {lang === "RO" ? "Camere" : "Rooms"}
          </h1>
          <p className="paragraph-standard">
            {lang === "RO" ? data.camere.text : data.camere.text_en}
          </p>

          {lang === "RO" &&
            data.camere.oferte
              .filter((el) => el.type === "simple")
              .map((el) => (
                <div>
                  <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                    <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                    <span>{el.part2}</span>
                  </p>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </div>
              ))}

          {lang === "RO" &&
            data.camere.oferte
              .filter((el) => el.type !== "simple")
              .map((el) => (
                <div>
                  <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                    <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                    <span>{el.part2}</span>
                  </p>
                  {el.part3.map((itm) => (
                    <p
                      className="paragraph-standard"
                      style={{ fontWeight: 400 }}
                    >
                      • <strong>{itm.camera}</strong> - <span>{itm.pret}</span>
                    </p>
                  ))}
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </div>
              ))}

          {lang === "EN" &&
            data.camere.oferte_en
              .filter((el) => el.type === "simple")
              .map((el) => (
                <p className="paragraph-standard" style={{ fontWeight: 400 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
              ))}

          {lang === "EN" &&
            data.camere.oferte_en
              .filter((el) => el.type !== "simple")
              .map((el) => (
                <div>
                  <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                    <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                    <span>{el.part2}</span>
                  </p>
                  {el.part3.map((itm) => (
                    <p
                      className="paragraph-standard"
                      style={{ fontWeight: 400 }}
                    >
                      • <strong>{itm.camera}</strong> - <span>{itm.pret}</span>
                    </p>
                  ))}
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </div>
              ))}

          <CamereList camere={data.camere} lang={lang} />
        </div>
      </div>

      <Separator />

      <Contact lang={lang} contact={data.contact} />

      <Footer
        nume={data.header.nume}
        logo={data.logo}
        fb={data.fblink}
        ig={data.iglink}
        yt={data.ytlink}
      />
    </div>
  );
}

export default App;
