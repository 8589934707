import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Separator from "./components/Separator";
import CamereList from "./components/CamereList";

import data from "./data/data.json";

export default function Politici() {
  const [lang, setLang] = useState("RO");
  const [policies, setPolicies] = useState([]);
  useEffect(() => {
    if (window.location.pathname.includes("/en/")) {
      setLang("EN");
      setPolicies(data.politici_en);
    } else {
      setLang("RO");
      setPolicies(data.politici);
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="body">
      <Navbar lang={lang} data={data} logo={data.logo} fb={data.fblink} />
      <div className="section-standard">
        <div className="container-std first">
          <h1 className="room-title">
            {lang === "RO" ? "Politici de rezervare" : "Reservation policies"}
          </h1>

          <p className="paragraph-standard">
            <strong>
              {lang === "RO"
                ? "Următoarele politici de rezervare se aplică în unitatea noastră de cazare:"
                : "The following reservation policies apply:"}
            </strong>
          </p>
          <ul>
            {policies.map((item) => (
              <li>
                <p className="paragraph-standard">{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Separator />

      <div className="section-standard">
        <div className="container-std">
          <h1 className="section-title">
            {lang === "RO" ? "Camere disponibile" : "Available Rooms"}
          </h1>
          <p className="paragraph-standard">
            {lang === "RO" ? data.camere.text : data.camere.text_en}
          </p>
          <CamereList camere={data.camere} lang={lang} />
        </div>
      </div>

      <Footer
        nume={data.header.nume}
        logo={data.logo}
        fb={data.fblink}
        ig={data.iglink}
        yt={data.ytlink}
      />
    </div>
  );
}
