import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import data from "../data/data.json";

import FacilitatiGrid from "./FacilitatiGrid";
import SliderSmall from "./SliderSmall";
import { Divider } from "@material-ui/core";

export default function RoomTop(props) {
  const [roomData, setRoomData] = useState({});
  useEffect(() => {
    setRoomData(props.roomData);
  }, [props]);
  // console.log(roomData);
  return (
    <div className="section-standard">
      <div className="container-std first">
        <h1 id="testelement" className="room-title">
          {props.lang === "RO" ? props.roomData.nume : props.roomData.nume_en}
        </h1>
        <div className="top-button-holder room">
          {roomData.pret &&
            roomData.pret.map((el) => (
              <div className="pers-holder no-space w-embed">
                {Array(el.capacitate)
                  .fill()
                  .map((el) => (
                    <img
                      key={Math.random()}
                      src="/images/app_img/person.svg"
                      style={{ height: 20, marginRight: 4 }}
                      alt=""
                    />
                  ))}
                {el.copii &&
                  Array(el.copii)
                    .fill()
                    .map((el) => (
                      <img
                        key={Math.random()}
                        src="/images/app_img/person.svg"
                        style={{ height: 12, marginRight: 4 }}
                        alt=""
                      />
                    ))}
                <strong style={{ marginLeft: 5 }}>
                  {el.pret} / {props.lang === "RO" ? "noapte" : "night"}
                  {el.wEnd && " (weekend)"}
                </strong>
              </div>
            ))}

          <Link
            className="button-inline w-button"
            to={
              props.lang === "EN"
                ? `/en/reservations/${roomData.roomId}`
                : `/rezervari/${roomData.roomId}`
            }
          >
            {props.lang === "RO" ? "Rezervare nouă" : "New reservation"}
          </Link>
        </div>
        <p className="paragraph-standard">
          {props.lang === "RO"
            ? props.roomData.descriere
            : props.roomData.descriere_en}
        </p>
        {props.lang === "RO" &&
          data.camere.oferte
            .filter((el) => el.type === "simple")
            .map((el) => (
              <div key={Math.random()}>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}

        {props.lang === "RO" &&
          data.camere.oferte
            .filter((el) => el.type !== "simple")
            .map((el) => (
              <div>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                {el.part3.map((itm) => (
                  <p className="paragraph-standard" style={{ fontWeight: 400 }}>
                    • <strong>{itm.camera}</strong> - <span>{itm.pret}</span>
                  </p>
                ))}
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}

        {props.lang === "EN" &&
          data.camere.oferte_en
            .filter((el) => el.type === "simple")
            .map((el) => (
              <div>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}

        {props.lang === "EN" &&
          data.camere.oferte_en
            .filter((el) => el.type !== "simple")
            .map((el) => (
              <div>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                {el.part3.map((itm) => (
                  <p className="paragraph-standard" style={{ fontWeight: 400 }}>
                    • <strong>{itm.camera}</strong> - <span>{itm.pret}</span>
                  </p>
                ))}
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}
        {roomData && roomData.foto && <SliderSmall images={roomData.foto} />}

        <p className="paragraph-standard">
          {props.lang === "RO"
            ? "Următoarele facilități sunte disponibile în acest tip de cameră:"
            : "The following amenities are included with this room type:"}
        </p>

        {roomData && roomData.facilitati && (
          <FacilitatiGrid fac={roomData.facilitati} lang={props.lang} />
        )}
      </div>
    </div>
  );
}
