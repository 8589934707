import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

export default function Hero(props) {
  return (
    <div>
      <div
        className="header"
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${props.data.header.image})`,
          backgroundPosition: props.data.header.imgPosition,
        }}
      >
        <h1 className="page-title">
          {props.lang === "EN"
            ? props.data.header.nume_en
            : props.data.header.nume}
        </h1>
        <p className="page-text">
          {props.lang === "EN"
            ? props.data.header.tagline_en
            : props.data.header.tagline}
        </p>

        <div className="div-block">
          <a
            href={props.data.contact.GPS}
            target="_blank"
            rel="noopener noreferrer"
            className="button-standard w-hidden-main w-hidden-medium w-hidden-small w-button"
          >
            {props.lang === "EN" ? "GPS navigation" : "navigare GPS"}
          </a>
          <a
            href={`tel:${props.data.contact.phone[0]}`}
            className="button-standard w-hidden-main w-hidden-medium w-hidden-small w-button"
          >
            {props.lang === "EN"
              ? `Call ${props.data.contact.phone[0]}`
              : `sună ${props.data.contact.phone[0]}`}
          </a>
          <NavLink to={props.lang === "EN" ? "/en#camere" : "/#camere"}>
            <div className="button-standard w-button">
              {props.lang === "EN"
                ? "Rooms & Reservations"
                : "Camere & rezervări"}
            </div>
          </NavLink>

          <NavLink to={props.lang === "EN" ? "/en#contact" : "/#contact"}>
            <div
              href="#contact"
              className="button-standard w-hidden-tiny w-button"
            >
              Contact
            </div>
          </NavLink>
        </div>
      </div>

      <div>
        <div className="container w-container">
          <div className="rezervari-box top-black">
            <div className="arrow-link w-inline-block">
              <img
                src="/images/app_img/arrow-down-white.png"
                className="arrow-down-white"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
