import React, { Component } from "react";
import moment from "moment";
import "moment/locale/ro";
import "react-dates/initialize";
import "../css/dates_over.css";
import "../css/react_dates_overrides.css";
// import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

if (window.location.pathname.includes("/en/")) {
  moment.locale("en");
} else {
  moment.locale("ro");
}

class DatesConst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment.unix(props.queryIn),
      endDate: moment.unix(props.queryOut),
      // focusedInput: true
    };
  }
  render() {
    this.props.setInDateState(moment(this.state.startDate).format("X"));
    this.props.setOutDateState(moment(this.state.endDate).format("X"));
    return (
      <DateRangePicker
        startDatePlaceholderText="Check In"
        endDatePlaceholderText="Check Out"
        startDateId="booking-form-startDate"
        endDateId="booking-form-endDate"
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        onDatesChange={({ startDate, endDate }) =>
          this.setState({ startDate: startDate, endDate: endDate })
        }
        focusedInput={this.state.focusedInput}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        // isDayBlocked={date => this.isAlreadyBooked(date)}
        showClearDates={true}
        regular={true}
        numberOfMonths={window.innerWidth > 500 ? 3 : 1}
        firstDayOfWeek={1}
        minimumNights={0}
        reopenPickerOnClearDates
        transitionDuration={0}
        displayFormat="D MMM 'YY"
        hideKeyboardShortcutsPanel
        readOnly
      />
    );
  }
}

export default DatesConst;
