import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "../App";
import Room from "../Room";
import Offer from "../Offer";
import ReservationRoom from "../ReservationRoom";
import ReservationOffer from "../ReservationOffer";
import Politici from "../Politici";
import Confirmare from "../Confirmare";
import Analytics from "react-router-ga";
// import data from "../data/data.json";
import data from "../data/data.json";

export default function Routes() {
  return (
    <BrowserRouter>
      <Analytics id={data.analytics}>
        <Switch>
          <Route path="/" component={() => <App lang="RO" />} exact={true} />
          <Route path="/en" component={() => <App lang="EN" />} exact={true} />
          <Route
            path="/confirmare"
            component={() => <Confirmare lang="RO" />}
            exact={true}
          />
          <Route
            path="/en/confirmation"
            component={() => <Confirmare lang="EN" />}
            exact={true}
          />
          <Route path="/en/" component={() => <App lang="EN" />} exact={true} />
          <Route
            path="/en/rooms/:room"
            component={() => <Room lang="EN" />}
            exact={true}
          />
          <Route
            path="/camere/:room"
            component={() => <Room lang="RO" />}
            exact={true}
          />
          <Route
            path="/oferte/:offer"
            component={() => <Offer lang="RO" />}
            exact={true}
          />
          <Route
            path="/en/offers/:offer"
            component={() => <Offer lang="EN" />}
            exact={true}
          />
          <Route
            path="/rezervari/:room"
            component={() => <ReservationRoom lang="RO" />}
            exact={true}
          />
          <Route
            path="/en/reservations/:room"
            component={() => <ReservationRoom lang="EN" />}
            exact={true}
          />
          <Route
            path="/oferte/rezervari/:offer"
            component={() => <ReservationOffer lang="RO" />}
            exact={true}
          />
          <Route
            path="/en/offers/reservations/:offer"
            component={() => <ReservationOffer lang="EN" />}
            exact={true}
          />
          <Route
            path="/en/reservation-policies/"
            component={() => <Politici lang="EN" />}
            exact={true}
          />
          <Route
            path="/politici-rezervare/"
            component={() => <Politici lang="RO" />}
            exact={true}
          />
        </Switch>
      </Analytics>
    </BrowserRouter>
  );
}
