import firebase from "firebase";



const firebaseConfig = {
  apiKey: "AIzaSyCsfxyN-oqthA_3Js_yhe4lrOw4a7QnAss",
  authDomain: "ah-micro.firebaseapp.com",
  databaseURL: "https://ah-micro.firebaseio.com",
  projectId: "ah-micro",
  storageBucket: "ah-micro.appspot.com",
  messagingSenderId: "1042596068365",
  appId: "1:1042596068365:web:f18113226957be0913e944",
  measurementId: "G-BT0ZKZKDYP"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

let db = firebase.firestore();


export { db };
